@mixin mobile() {
    @media(width <= 480px) {
        @content;
    }
}

@mixin tablet() {
    @media (width <= 768px) {
        @content;
    }
}

@mixin lapto() {
    @media(width <= 1024px) {
        @content;
    }
}

@mixin extraLargeScreen() {
    @media(width > 1024px) {
        @content;
    }
}